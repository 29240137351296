'use strict';

angular.module('enervexSalesappApp').controller('GlobalDocumentsCtrl', function(Auth, $scope, GlobalDocument,Flash, Util, Account) {
	$scope.jobLabel = Util.jobLabel;
	$scope.isAdmin = Auth.isAdmin;
	$scope.accessAllAccounts = Auth.accessAllAccounts;
	$scope.allAccounts = [];
	$scope.offset = 0;
	$scope.accountLimit = 250;
	$scope.ctx = {
		startedAt: moment().subtract(parseInt(1), 'weeks').toDate(),
		endedAt: moment().add(parseInt(1), 'day').toDate(),
		account: ''
	}
	function fetchAccounts() {
		$scope.accounts = []
		var limit = $scope.accountLimit
		Account.query({
			offset:$scope.offset,
			limit: limit,
			accountType: 'commercial'
		}).$promise.then(function(accounts){
			if (accounts.length > 0) {
				$scope.allAccounts = $scope.allAccounts.concat(accounts);
				$scope.offset = $scope.offset + limit;
				fetchAccounts();
			} else {
				$scope.accounts = _.sortBy($scope.allAccounts, function(account){
					account.displayName = account.prefixCode && account.prefixCode != '' ? account.name + " ("+account.prefixCode+ ")" : account.name
					return account.name && account.name.toLowerCase()
				})
				$scope.ctx.httpLoading = false
			}
		});
	}

	function fetch(topLevel) {
		if (!topLevel) {
			return
		}
		var startedAt = moment($scope.ctx.startedAt).toISOString()
		var endedAt = moment($scope.ctx.endedAt).toISOString()
		Flash.clear()
		Flash.create('info', '<strong>Searching</strong> proposals....', 0, {}, true);
		var params = {
			startedAt: startedAt,
			endedAt: endedAt
		}
		if ($scope.ctx.accountId && $scope.ctx.accountId != '') {
			params.account = $scope.ctx.accountId
		}

		GlobalDocument.query(params).$promise.then(function(documents){
			//flash
			Flash.clear()
			Flash.create('info', '<strong>Search</strong> Found ' + documents.length + " proposals", 0, {}, true);
			$scope.documents = documents;
		});
	}
	fetchAccounts()
	fetch(true)
	$scope.limit = 5;
	$scope.removeLimit = function(){
		$scope.showAll = true;
		$scope.limit = $scope.documents.length;
	}
	$scope.placeLimit = function(){
		$scope.showAll = false;
		$scope.limit = 5;
	}
	$scope.export = function() {
		var startedAt = moment($scope.ctx.startedAt).toISOString()
		var endedAt = moment($scope.ctx.endedAt).toISOString()
		var url = "/api/documents/export?startedAt="+startedAt + "&endedAt="+endedAt;
		if ($scope.ctx.accountId && $scope.ctx.accountId != '') {
			url=url+"&account="+$scope.ctx.accountId
		}
		window.location.href = url;
	}
	$scope.change = function(topLevel) {
		fetch(topLevel)
	}
	$scope.exportLineitems = function(doc) {
		var url = "/api/accounts/" + doc.job.account._id + "/jobs/" + doc.job._id + "/quotes/" + doc.quote.quote._id +"/export-lineitems";
		window.location.href = url;
	}
});
